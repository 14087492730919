// clases de texto
.fuente-title {
	font-family: "Gotham Bold";
	line-height: 1.2em;
}
.fuente-body {
	font-family: "Gotham Light";
}
.color-primary {
	color: $color-primary;
}
.color-secondary {
	color: $color-secondary;
}
.color-txtgrey {
	color: $txt-grey;
}
.color-negro {
	color: $negro;
}

.title-xxl {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxl);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-l);
	}
}
.title-xl {
	@extend .fuente-title;
	@include fontSize($titulo-size-xl);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-m);
	}
}
.title-l {
	@extend .fuente-title;
	@include fontSize($titulo-size-l);
}
.title-m {
	@extend .fuente-title;
	@include fontSize($titulo-size-m);
}
.title-s {
	@extend .fuente-title;
	@include fontSize($titulo-size-s);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-xxs);
	}
}
.title-xs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xs);
}
.title-xxs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxs);
}
.title-xxxs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxxs);
}

.body-xxl {
	@extend .fuente-body;
	@include fontSize($body-size-xxl);
}
.body-xl {
	@extend .fuente-body;
	@include fontSize($body-size-xl);
}
.body-l {
	@extend .fuente-body;
	@include fontSize($body-size-l);
}
.body-m {
	@extend .fuente-body;
	@include fontSize($body-size-m);
}
.body-s {
	@extend .fuente-body;
	@include fontSize($body-size-s);
}
.body-xs {
	@extend .fuente-body;
	@include fontSize($body-size-xs);
}

.title-nobold {
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $blanco;
	margin-bottom: 0;
}
p {
	margin-bottom: 0;
	a {
		color: $txt-grey;
		text-decoration: underline;
	}
}

strong {
	@extend .fuente-title;
}

:focus {
	outline: 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($negro, 0.55);
}

.borde-y-negro {
	border-top: solid 2px $negro;
	border-bottom: solid 2px $negro;
}

.borde-negro {
	border: solid 2px $negro;
}

.borde-bottom-negro {
	border-bottom: solid 2px var(--mcdonaldred);
}

/* FORMULARIOS */

input.form-control {
	border: none;
	border-radius: 0;
	height: 50px;
	text-align: center;
	@include fontSize($body-size-m);
	.has-error & {
		border: 1px solid $error;
	}
}
select.form-control {
	width: 100%;
	height: 38px;
	border: none;
	background-color: #ebebeb;
	border: 1px solid #ebebeb;
	text-align: left;
	font-family: "Gotham Light";
	option {
		font-family: "Gotham Light";
	}
}
.cont-custom-select{
	position: relative;
	select{
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		display: inline-block;
		width: 100%;
		height: 40px;
		cursor: pointer;
		padding: 7px 10px;
		outline: 0; 
		border: 0;
		border-radius: 0;
		background: #ebebeb;
		color: black;
		font-size: 18px;
		position: relative;
		transition: all 0.25s ease;
		&:hover{
			background: rgb(232,232,232);
			background: -moz-linear-gradient(180deg, rgba(232,232,232,1) 0%, rgba(244,244,244,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(232,232,232,1) 0%, rgba(244,244,244,1) 100%);
			background: linear-gradient(180deg, rgba(232,232,232,1) 0%, rgba(244,244,244,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8e8e8",endColorstr="#f4f4f4",GradientType=1);
		}
	}
	i{
		position:absolute;
		right: 10px;
  		top: 10px;
	}
}
#navmapa, #navlistado{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cont-custom-select select::-ms-expand {
	display: none;
}

.class_option {
	font-family: "Gotham Light" !important;
}
.btn {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.btn-link {
	color: $txt-grey;
	text-decoration: underline;
}
.btn-primary {
	@extend .fuente-title;
	background-color: var(--mcdonaldred);
	@include fontSize($body-size-l);
	color: $blanco;
	border: none;
	border-radius: 0;
	height: 50px;
	text-align: center;
	position: relative;
	border-radius: 10px;
	@include transition(all 0.4s ease-out);
	&:hover {
		background-color: $blanco !important;
		color: var(--mcdonaldred) !important;
	}
	&.disabled {
		background-color: var(--mcdonaldred);
		color: $negro;
		opacity: 1;
		pointer-events: all;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
		}
	}
}

.btn-secondary {
	@extend .fuente-title;
	background-color: var(--mcdonaldred);
	@include fontSize($body-size-l);
	color: $blanco !important;
	border: none;
	border-radius: 0;
	height: 50px;
	line-height: 41px;
	text-align: center;
	position: relative;
	&.disabled {
		background-color: var(--mcdonaldred);
		color: $blanco;
		opacity: 1;
		pointer-events: all;
	}
	&:hover {
		background-color: $negro !important;
		color: $blanco;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
			@include filter(invert(100%));
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
		border-radius: 10px;
	}

	&.btn-xs {
		@include fontSize(12px);
		height: 27px;
		line-height: 23px;
		padding: 2px 20px;
		position: absolute;
		top: -2px;
		white-space: nowrap;
		// right: 0px;/*-140*/
	}
	&.btn-gold{
		background-color: var(--mcdonaldgold);
		color: $negro !important;
		&:hover {
			background-color: var(--mcdonaldred) !important;
			color: $blanco!important;
		}
		
	}
}
.btn-negro {
	@extend .fuente-title;
	background-color: $negro;
	@include fontSize($body-size-l);
	color: $blanco !important;
	border: none;
	border-radius: 0;
	height: 50px;
	line-height: 41px;
	text-align: center;
	position: relative;
	&.disabled {
		background-color: $negro;
		color: $blanco;
		opacity: 1;
		pointer-events: all;
	}
	&:hover {
		background-color: $txt-grey !important;
		color: $negro;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
			@include filter(invert(100%));
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize(12px);
		height: 27px;
		line-height: 23px;
		padding: 2px 30px;
		position: absolute;
		top: -2px;
		white-space: nowrap;
		// right: 0px;/*JC quito -140*/
	}
}
.btn-skew {
	// transform: skewX(-15deg);
	background-color: var(--mcdonaldgold);
	border-radius: 10px;
	margin-top: 30px;
	margin-bottom: 60px;
	padding: 7px 90px;
	color: $negro;
	font-family: "Gotham Bold";
	span {
		// transform: skewX(15deg);
		display: block;
	}
	&:hover {
		background-color: var(--mcdonaldred);
		color: $blanco;
	}
	&.btn-skew-small {
		padding: 5px 40px;
	}
}

.bg-gris {
	background-color: $bg-grey;
}
.bg-gris-oscuro {
	background-color: #d2d2d2;
}
.btn-terciary {
	font-family: "Gotham Book";
	background-color: $bg-grey;
	@include fontSize(15px);
	color: $negro;
	border-radius: 0;
	height: 26px;
	line-height: 1em;
	text-align: center;
	transition: color 0.3s;
	position: relative;
	margin-left: 0 !important;
	@include media-breakpoint-down(md) {
		@include fontSize(14px);
		padding: 0 5px;
		line-height: 23px;
		height: 23px;
	}
	&:hover,
	&.active {
		background-color: #DB0007 !important;
		color: $blanco;
	}

	&.disabled {
		background-color: $bg-grey;
		color: $negro;
		opacity: 1;
		pointer-events: all;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize($body-size-xs);
		height: 33px;
		line-height: 24px;
	}
}
select:not(.js-idiomas) {
	@include media-breakpoint-down(md) {
		@include fontSize(16px);
		width: calc(65vw - 50px) !important;
	}
}
.selector{
	background-color: transparent;
	position: absolute;
	top: -35px;
	right: 15px;
	z-index: 1050;
	@include media-breakpoint-down(sm) {
		position: inherit;
		top: 0;
		right: 0;
	}
	@include media-breakpoint-down(sm) {
		background-color: #ebebeb;
	}
	select.js-idiomas {

	}
}


// .btn-secondary{

// 	border-radius: 0px;

// 	&.btn-xl{

// 		@extend .fuente-title;
// 		@include fontSize($body-size-l);
// 		color:$blanco;
// 		line-height: 43px;
// 		background-color: $txt-grey;
// 		border: none;
// 		border-radius: 0;
// 		height: 50px;
// 		text-align: center;
// 		transition:color 0.3s;
// 		position:relative;
// 		width: 400px;
// 		max-width: 100%;
// 		&:hover{
// 			background-color:$blanco!important;
// 			color:$negro;
// 		}

// 	}

// }

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.tooltip {
	font-size: 10px;
	.arrow::before {
		border-right-color: $blanco;
	}

	.tooltip-inner {
		background-color: $blanco;
		color: $negro;
	}
}

.tooltip-inner {
	text-align: left;
	p {
		text-indent: -8px;
		padding-left: 10px;
	}
}

.alert {
	display: none;
	padding: 5px 15px;
	margin-top: 10px;
	border-radius: 0;
	.mensaje-alerta {
		font-weight: 700;
		color: #000;
		font-size: 1rem;
		display: inline-block;
		margin-top: 20px;
		ul {
			list-style: none;
			padding-left: 15px;
			margin-bottom: 10px;
			li {
				font-weight: 300;
				color: #000;
				font-size: 0.8rem;
				&:before {
					content: "\f057";
					font-family: FontAwesome;
					font-size: 0.8rem;
					color: red;
					margin-right: 5px;
				}
			}
		}
	}
}
.has-error {
	.campo,
	textarea,
	select {
		border: 2px solid #c60000 !important;
	}
	.chosen-container {
		.chosen-single {
			border: 2px solid #c60000 !important;
		}
	}
	.icheckbox_square-green {
		background-position: -28px 0;
	}
	.checkbox {
		&:before {
			border: 2px solid #c60000 !important;
		}
	}
}

.modal-title {
	color: #000;
}

.up {
	display: block;
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 99999;
}

.embed-responsive-4by3::before {
	padding-top: 62.5%;
	position:relative;
	display:block;
	content:"";
}

.embed-responsive{

	position:relative;
	overflow: hidden;

	img{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;		
	}

}

.alert.fade {
	opacity: 0;
	transition: opacity 0.2s linear;
	&.show {
		opacity: 1;
		display: block;
	}
}
.alert-dismissible .close {
	padding: 5px 15px 4px;
}


#modal-validado, #modal-descargarbono, #modal-bonook, #modal-alerta{
	.modal-title{
		color:black!important;
	}
	.modal-body{
		p{
			// color:var(--mcdonaldred)!important;
			color:black!important;
			font-weight: bold;
			@include fontSize(22px);
			line-height: 1.2em;
						
		}
		.btn-secondary{
			background-color:#DB0007!important; 
			color:white!important;
		}
	}
}
#modal-alerta{
	color:black!important;
	// font-weight: bold;
	@include fontSize(20px);
	line-height: 1.2em;
}