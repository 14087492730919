// *****************************************************
// CAMBIOS LIBRERIAS MCDONALDS
// *****************************************************

@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 576px) {
      @content;
    }
  }
  @else if $breakpoint == sm {
    @media (max-width: 768px) {
      @content;
    }
  }
  @else if $breakpoint == md {
    @media (max-width: 992px) {
      @content;
    }
  }
  @else if $breakpoint == lg {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @else if $breakpoint == xl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @if $breakpoint == xs {
    @media (min-width: 576px) {
      @content;
    }
  }
  @else if $breakpoint == sm {
    @media (min-width: 768px) {
      @content;
    }
  }
  @else if $breakpoint == md {
    @media (min-width: 992px) {
      @content;
    }
  }
  @else if $breakpoint == lg {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if $breakpoint == xl {
    @media (min-width: 1400px) {
      @content;
    }
  }
}
// *****************************************************
// *****************************************************

/**
 * transiciones de elementos
 * @param  {[all 1s ease]} $value 
 */
@mixin transition($value) {
    -webkit-transition:$value; /* Safari */
    -moz-transition: $value;
    -o-transition:$value;
    transition:$value;
}

/**
 * bordes curvos
 * @param  {[supizq supder infder infizq]} $value 
 */
@mixin border-radius($value) {
  -webkit-border-radius: $value;
     -moz-border-radius: $value;
      -ms-border-radius: $value;
          border-radius: $value;
}

/**
 * sombra de cajas
 * @param  {[h-shadow v-shadow blur spread color]} $value 
 */
@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

/**
 * sombra de texto
 * @param  {[h-shadow v-shadow blur spread color]} $value 
 */
@mixin text-shadow($value) {
  -webkit-text-shadow: $value;
  -moz-text-shadow: $value;
  text-shadow: $value;
}

/**
 * transformacion css
 * @param  {usar valores de la transformacion necesaria} $value 
 */
@mixin transform($value){
    -ms-transform: $value; /* IE 9 */
    -webkit-transform: $value; /* Chrome, Safari, Opera */
    transform: $value;

}

@mixin filter($value){
    -webkit-filter: $value; /* Chrome, Safari, Opera */
    filter: $value;

}

/**
 * [giroinfinito :: hace giro infinito al objeto]
 * @param  {[type]} $duracion [velocidad de una vuelta en segundos]
 */
@mixin giroinfinito($duracion){
    -webkit-animation-name: spin;
    -webkit-animation-duration: $duracion;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: $duracion;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: $duracion;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-transition: rotate(3600deg);

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

}

/**
 * [placeholder :: estilos para placeholder]
 * @param  {[type]} 
 */
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: $size / $size-rem;
//Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}



$spaceamounts: (0, 5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,100,115,125,150,175,200,225,250,275,300,350,400,450,500);
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .mar-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .pad-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
  .mar-#{$space} {
    margin: #{$space}px !important;
  }

  .pad-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .mar-#{str-slice($side, 0, 1)}-md-#{$space} {
      @include media-breakpoint-up(md) {
        margin-#{$side}: #{$space}px !important;
      }
      @media (max-height: 900px) {
        margin-#{$side}: #{$space/2}px !important;
      }
    }
  
    .pad-#{str-slice($side, 0, 1)}-md-#{$space} {
      @include media-breakpoint-up(md) {
        padding-#{$side}: #{$space}px !important;
      }
      @media (max-height: 900px) {
        padding-#{$side}: #{$space/2}px !important;
      }
    }
  }
  .mar-md-#{$space} {
    @include media-breakpoint-up(md) {
      margin: #{$space}px !important;
    }
      @media (max-height: 900px) {
      margin: #{$space/2}px !important;
      }
  }

  .pad-md-#{$space} {
    @include media-breakpoint-up(md) {
      padding: #{$space}px !important;
    }
      @media (max-height: 900px) {
      padding: #{$space/2}px !important;
      }
  }
}
